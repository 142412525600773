<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">Attributess</h6>
      </b-row>
    </b-card>
    <div>
      <b-form ref="form" class="repeater-form">
        <!-- Row Loop -->
        <div ref="row" class="pb-2">
          <b-card>
            <b-row v-if="item[0] && item[0].title">
              <b-col md="12">
                <b-row>
                  <!-- Attributes Name -->
                  <b-col md="4">
                    <b-form-group label="Attributes Name" label-for="attributes-name">
                      <b-form-input id="attributes-name" type="text" v-model="item[0].title" placeholder="Attributes Name" />
                    </b-form-group>
                  </b-col>
                  <!-- Attributes Code -->
                  <b-col md="4">
                    <b-form-group label="Attributes Required" label-for="attributes-required">
                      <b-form-select id="attribute-required" v-model="item[0].required" :options="optionsRequired" />
                    </b-form-group>
                  </b-col>

                  <!-- Attributes Type -->
                  <b-col md="4">
                    <b-form-group label="Attributes Type" label-for="attributes-type">
                      <b-form-select id="attribute-type" v-model="item[0].type" :options="optionsType" />
                    </b-form-group>
                  </b-col>

                  <b-col md="4" v-if="item[0].type == 'multi'">
                    <b-form-group label="Attributes Minimum Amount To Select" label-for="attributes-min_select">
                      <b-form-input id="attributes-min_select" type="text" v-model="item[0].min_select" placeholder="Attributes Min selection" />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="item[0].type == 'multi'">
                    <b-form-group label="Attributes Maxiumum Amount To Select" label-for="attributes-max_select">
                      <b-form-input id="attributes-max_select" type="text" v-model="item[0].max_select" placeholder="Attributes Max selection" />
                    </b-form-group>
                  </b-col>

                  <!-- Content -->
                  <b-col md="12">
                    <b-form-group :label="'Description'" label-for="category-description">
                      <b-form-textarea id="category-description" type="text" v-model="item[0].content" placeholder="Attributes Description" />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group :label="'Pirority'" label-for="category-description">
                      <b-form-input id="attributes-priority" type="text" v-model="item[0].priority" placeholder="Priority" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Options -->
                <template v-if="item[0].type == 'select' || item[0].type == 'multi'" :ref="'rowSub'" class="row">
                  <h5>Options List</h5>
                  <b-row v-for="(sub, subIndex) in item[0].option_values" :key="subIndex">
                    <b-col md="6">
                      <b-form-group label="Option Name" :label-for="'option-name' + subIndex">
                        <b-form-input :id="'option-name' + subIndex" type="text" v-model="sub.value" placeholder="Option Name" />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Option Price Change" :label-for="'attributes-price' + subIndex">
                        <b-form-input :id="'attributes-price' + subIndex" type="number" v-model="sub.price" placeholder="Option Price Change" />
                      </b-form-group>
                    </b-col>

                    <b-col md="2" v-if="subIndex != 0">
                      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" class="mt-0 mt-md-2" block @click="removeSubItem(subIndex)">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col md="2" v-if="subIndex == 0">
                      <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="info" class="mt-0 mt-md-2" block @click="addSubItem()">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Add Option</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>

            <b-row>
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveOption()">
                  <feather-icon icon="SaveIcon" class="mr-25" />
                  <span>Save & Close</span>
                </b-button>
              </b-col>
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveOption(true)">
                  <feather-icon icon="SaveIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>
              </b-col>
              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="danger" class="mt-0 mt-md-2" block @click="removeItem()">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
              <b-col lg="2" md="2" class="mb-50">
                <router-link tag="div" :to="'/takeaway/products/attributes/'" class="ml-25 mt-2 btn btn-warning pull-right">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Cancel</span>
                </router-link>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard, BFormTextarea } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    vSelect,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: ["id"],

  data() {
    return {
      item: [],
      attributes_raw: [],
      products_raw: [],
      productsOptions: [],

      optionsRequired: [
        {
          value: 0,
          text: "No",
        },
        {
          value: 1,
          text: "Yes",
        },
      ],
      optionsType: [
        {
          value: "multi",
          text: "Multiselect Options",
        },
        {
          value: "select",
          text: "Options",
        },
        {
          value: "text",
          text: "Free text Input",
        },
      ],
    };
  },

  methods: {
    async saveOption(stay = false) {
      await useJwt
        .patch_attribute(this.id, {
          title: this.item[0].title,
          content: this.item[0].content ? this.item[0].content : " ",
          type: this.item[0].type,
          option_values: JSON.stringify(this.item[0].option_values),
          required: this.item[0].required,
          max_select: this.item[0].max_select,
          priority: this.item[0].priority,
          min_select: this.item[0].min_select,
        })
        .then(async () => {
          // await useJwt
          //   .delete_attribute_product(this.id)
          //   .then(async () => {
          // if (this.item[0].products.length) {
          //   for (let x = 0; x < this.item[0].products.length; x++) {
          //     await useJwt
          //       .post_product_attributes("create", {
          //         product_id: this.item[0].products[x].value,
          //         attribute_id: this.id,
          //       })
          //       .then(() => {
          //         this.$forceUpdate();
          //       });
          //   }
          // }
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Attribute saved`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully saved attribute.`,
            },
          });
          this.$forceUpdate();
          if (!stay) this.$router.push("/takeaway/products/attributes");
          // })

          // .catch(() => {
          //   this.$toast({
          //     component: ToastificationContent,
          //     position: "top-right",
          //     props: {
          //       title: `Error`,
          //       icon: "CropIcon",
          //       variant: "danger",
          //       text: `We couldn't update product data on the server.`,
          //     },
          //   });
          // });
        })
        .catch((e) => {
          console.error(e);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new attribute on the server.`,
            },
          });
        });
    },
    async removeItem() {
      await useJwt
        .delete_attribute_product(this.id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch(() => {
          return this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't update product data on the server.`,
            },
          });
        });
      return await useJwt
        .delete_attribute(this.id)
        .then(async (response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Attribute removed`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully removed attribute.`,
            },
          });
          this.$router.push("/takeaway/products/attributes");
        })
        .catch((e) => {
          console.error(e);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't create new attribute on the server.`,
            },
          });
        });
    },

    removeSubItem(subItem) {
      this.item[0].option_values.splice(subItem, 1);
    },

    addSubItem() {
      this.item[0].option_values.push({
        value: "",
        price: 0,
      });
    },
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.item = val;
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    this.$forceUpdate();
  },
  mounted() {
    let id = this.$route.params.id;
    if (localStorage.getItem("userProducts")) {
      this.products_raw = JSON.parse(localStorage.getItem("userProducts"));
    } else {
      this.$router.push("/takeaway/products/attributes");
    }

    if (localStorage.getItem("userAttributes")) {
      this.attributes_raw = JSON.parse(localStorage.getItem("userAttributes"));
    } else {
      this.$router.push("/takeaway/products/attributes");
    }

    let item = {};
    for (let x = 0; x < this.attributes_raw.length; x++) {
      if (this.attributes_raw[x].id == id) {
        item = this.attributes_raw[x];
        break;
      }
    }

    for (let y = 0; y < this.products_raw.length; y++) {
      this.productsOptions.push({
        value: this.products_raw[y].id,
        label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
      });
    }

    item.option_values = JSON.parse(item.option_values);

    this.item.products = [];
    let attributes_temp;
    item.products = [];
    for (let y = 0; y < this.products_raw.length; y++) {
      if (this.products_raw[y].attributes) {
        attributes_temp = this.products_raw[y].attributes.split(",");

        console.log(this.products_raw);
        if (attributes_temp.length) {
          for (let z = 0; z < attributes_temp.length; z++) {
            if (attributes_temp[z] == id) {
              item.products.push({
                value: this.products_raw[y].id,
                label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
              });
            }
          }
        }
      }
    }
    item = JSON.stringify(item);
    this.item.push(JSON.parse(item));
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
